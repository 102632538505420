import DashboardIcon from "@mui/icons-material/Dashboard"
import SpokeIcon from "@mui/icons-material/Spoke"
import CreditScoreIcon from "@mui/icons-material/CreditScore"
import SubscriptionsIcon from "@mui/icons-material/Subscriptions"
import PaymentsIcon from "@mui/icons-material/Payments"
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet"


export const routes = [
  {
    name: "Overview",
    icon: <DashboardIcon />,
    route: "/dashboard",
  },
  {
    name: "domains",
    icon: <SpokeIcon />,
    route: "/domains",
  },
  {
    name: "Settings",
    icon: <SubscriptionsIcon />,
    route: "/settings",
  },
  {
    name: "Documentation",
    icon: <PaymentsIcon />,
    route: "/documentation",
  },
  {
    name: "Support",
    icon: <CreditScoreIcon />,
    route: "/support",
  },
  {
    name: "Business-portal",
    icon: <AccountBalanceWalletIcon />,
    route: "/business-portal",
  },
]

export const appBarRoutes = [
  {
    name: "apiDocumentation",
    route: "https://appmodule.net",
  },
  {
    name: "ticketing",
    route: "https://appmodule.net",
  },
  // {
  //   name: "contact",
  //   route: "/contact",
  // },
]
