import React, { forwardRef, useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"
import { TransitionProps } from "@mui/material/transitions"
import ContainedButton from "../../basic/buttons/ContainedButton"
import { Divider, Typography } from "@mui/material"
import { ReactComponent as Close } from "../../../assets/icons/closeWhite.svg"
import { ReactComponent as Copy } from "../../../assets/icons/copy.svg"
import CloseSharpIcon from "@mui/icons-material/CloseSharp"
import GridItem from "../../basic/grid/GridItem"
import GridContainer from "../../basic/grid/GridContainer"
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

interface Props {
  openApiKeyInfoDialog: boolean
  setOpenApiKeyInfoDialog: React.Dispatch<React.SetStateAction<boolean>>
  apiKeyCode: any | undefined
  setApiKeyCode: React.Dispatch<React.SetStateAction<any | undefined>>
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const styles = {
  domainButton: {
    textTransform: "none",
    fontWeight: "bold",
  },
  iconSend: {
    transform: "rotate(-40deg)",
    mb: "6px",
  },
  buttonCancel: {
    mt: "5px",
    textTransform: "none",
    fontWeight: "bold",
  },
  buttonDelete: {
    mt: "5px",
    textTransform: "none",
    fontWeight: "bold",
    mr: "-8px",
  },
  iconClose: {
    ml: "auto",
    mt: "3px",
    cursor: "pointer",
    color: "#6e6e6e",
  },
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DeleteModal = ({ apiKeyCode, setApiKeyCode, openApiKeyInfoDialog, setOpenApiKeyInfoDialog }: Props) => {
  const [tooltipText, setTooltipText] = useState("Copy to clipboard")

  const closeModalAndResetData = () => {
    setOpenApiKeyInfoDialog(false)
    setApiKeyCode("")
  }

  const copyData = () => {
    navigator.clipboard
      .writeText(apiKeyCode)
      .then(() => {
        setTooltipText("Copied !")
        setTimeout(function () {
          setTooltipText("Copy to clipboard")
        }, 3000)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }

  return (
    <div>
      <Dialog
        open={openApiKeyInfoDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeModalAndResetData}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: "flex" }}>
          <CloseSharpIcon onClick={closeModalAndResetData} sx={styles.iconClose} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography sx={{ mb: "25px" }}>Copy and store this key safe - it cannot be recovered!</Typography>
          <GridContainer>
            <GridItem sx={{ fontSize: "15px", width: "auto" }}>{apiKeyCode}</GridItem>
            <LightTooltip title={tooltipText}>
              <Copy onClick={copyData} style={{ marginLeft: "15px", marginBottom: "-5px", cursor: "pointer" }} />
            </LightTooltip>
          </GridContainer>
        </DialogContent>
        <Divider />

        <DialogContent>
          <DialogActions>
            <ContainedButton sx={styles.buttonCancel} onClick={() => setOpenApiKeyInfoDialog(false)} endIcon={<Close />}>
              Close
            </ContainedButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default DeleteModal
