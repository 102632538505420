import React from "react"
import GridItem from "../basic/grid/GridItem"

interface Props {
  value: string
}

const styles = {
  width: "100px",
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "12px",
  fontWeight: "bold",
  color: "#000000",
  borderRadius: "8px",
}

const StatusRenderer = ({ value }: Props) => {
  let cellValue = ""
  let bgColor = ""
  let color = ""

  if (value === "AVAILABLE") {
    cellValue = "Available"
    bgColor = "#e6f2fe"
    color = "#0d83fe"
  } else if (value === "OFFLINE") {
    cellValue = "Offline"
    bgColor = "#fdecec"
    color = "#ef4444"
  } else if (value === "EXPIRED") {
    cellValue = "Expired"
    bgColor = "#f5e0df"
    color = "#c92a1e"
  } else if (value === "INVALID") {
    cellValue = "Invalid"
    bgColor = "#fce4ac"
    color = "#855d03"
  }

  return (
    <GridItem sx={styles} style={{ backgroundColor: bgColor, color: color }}>
      {cellValue}
    </GridItem>
  )
}

export default StatusRenderer
