import React from "react"

import "./table-styles.css"

const DateRenderer = (props: { date: string }) => {
  const date: string = props.date

  if (date != null) {
    const dateDate = new Date(date)
    const result =
      dateDate.getDate().toString().padStart(2, "0") + "." + (dateDate.getMonth() + 1).toString().padStart(2, "0") + "." + dateDate.getFullYear()
    return <span>{result}</span>
  }

  return <span></span>
}

export default DateRenderer
