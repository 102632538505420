import React, { useRef, useMemo, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { AgGridReact } from "ag-grid-react"
import { GetContextMenuItemsParams, LicenseManager } from "ag-grid-enterprise"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined"
import "../../../customCss/table-styles.css"
import CenterHeader from "../../basic/grid/CenterHeader"
import { APIKey } from "../../../repository/ApiKeysRepository"
import StatusApiKeyRenderer from "../../table/StatusApIKeyRenderer"
import DateRenderer from "../../table/DateRenderer"

LicenseManager.setLicenseKey(
  "CompanyName=Appmodule AG,LicensedGroup=aliunid grid / power,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-036901,SupportServicesEnd=31_January_2024_[v2]_MTcwNjY1OTIwMDAwMA==4c9d5d4b04b142bc6b50ed895e5b5ef3"
)

interface Props {
  setOpenCreateApiKeyDialog: React.Dispatch<React.SetStateAction<boolean>>
  setOpenDeleteApiKeyDialog: React.Dispatch<React.SetStateAction<boolean>>
  setApiKeyInfoData: React.Dispatch<React.SetStateAction<APIKey | undefined>>
  rowData: APIKey[]
}

const ApiKeysGrid = ({ setOpenCreateApiKeyDialog, setApiKeyInfoData, setOpenDeleteApiKeyDialog, rowData }: Props) => {
  const navigate = useNavigate()
  const gridRef = useRef<AgGridReact>(null)

  const columnDefs = [
    {
      field: "description",
      headerName: "Description",
      filter: true,
      cellStyle: function () {
        return { display: "flex", alignItems: "center", fontWeight: "bold" }
      },
      minWidth: 200,
    },
    {
      field: "shortToken",
      headerName: "ID",
      filter: true,
      cellStyle: function () {
        return { display: "flex", alignItems: "center", fontWeight: "bold" }
      },
      minWidth: 100,
    },
    {
      field: "createdAt",
      headerName: "Created date",
      cellStyle: function () {
        return { fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "start" }
      },
      cellRenderer: (row: any) => <DateRenderer date={row.data.createdAt} />,
      minWidth: 120,
    },
    {
      field: "expiration",
      headerName: "Expiration date",
      cellStyle: function () {
        return { fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "start" }
      },
      cellRenderer: (row: any) => <DateRenderer date={row.data.expiration} />,
      minWidth: 100,
    },
    {
      field: "status",
      headerName: "Status",
      headerComponent: CenterHeader,
      cellRenderer: (row: any) => <StatusApiKeyRenderer value={row.data.expiration} />,
      cellStyle: function () {
        return { display: "flex", alignItems: "center", justifyContent: "center" }
      },
      minWidth: 100,
    },
    {
      field: "actions",
      headerName: ". . .",
      cellRenderer: (row: any) => <MenuOutlinedIcon sx={{ color: "#666666" }} />,
      cellStyle: function () {
        return { display: "flex", alignItems: "center", cursor: "pointer"}
      },
      maxWidth: 70,
      minWidth: 70,
      onCellClicked: function (params: any) {
        if (params.node.data.name !== "") {
          params.api.contextMenuFactory.showMenu(params.node, params.column, params.value, params.event)
        }
      },
    },
  ]

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      wrapText: false,
      suppressMovable: true,
      flex: 1,
      suppressMenu: true,
    }),
    []
  )

  const rowHeight = 55

  const cellClicked = useCallback((row: any) => {
    if (typeof row.data !== "undefined" && row.column.colId !== "actions" && row.column.colId !== "action") {
      navigate(`apiKey/${row.data.shortToken}`)
    }
  }, [navigate])

  const getRowStyle = (params: any) => {
    return { background: "white", cursor: "pointer" }
  }

  const editIcon = '<img border="0" width="15" height="15" src="/images/edit_icon.svg"/>'
  const searchIcon = '<img border="0" width="15" height="15" src="/images/icon.svg"/>'
  const deleteIcon = '<img border="0" width="15" height="15" src="/images/delete_icon.svg"/>'

  const getContextMenuItems = useCallback((params: GetContextMenuItemsParams) => {
    const result = []
    if (params.node) {
      result.push(
        {
          name: "Edit",
          icon: editIcon,
          action: function () {
            setApiKeyInfoData(params.node?.data)
            setOpenCreateApiKeyDialog(true)
          },
        },
        "separator"
      )
      result.push(
        {
          name: "Delete",
          icon: deleteIcon,
          action: function () {
            setApiKeyInfoData(params.node?.data)
            setOpenDeleteApiKeyDialog(true)
          },
        },
        "separator"
      )
      result.push({
        name: "Api Key details",
        icon: searchIcon,
        action: function () {
          if (typeof params.node?.data !== "undefined" && params.node.data.name !== "") {
            navigate(`apiKey/${params.node.data.shortToken}`)
          } else {
            //setMpData(params.node?.data)
          }
        },
      })
    }
    return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className="ag-theme-alpine domainsGrid" style={{ width: "auto", height: "calc(100vh - 350px)" }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          animateRows={true}
          rowSelection="multiple"
          groupDisplayType="groupRows"
          groupDefaultExpanded={1}
          defaultColDef={defaultColDef}
          onCellClicked={cellClicked}
          suppressContextMenu={true}
          rowHeight={rowHeight}
          getRowStyle={getRowStyle}
          getContextMenuItems={getContextMenuItems}
          pagination={true}
          paginationAutoPageSize={true}
        />
      </div>
    </div>
  )
}

export default ApiKeysGrid
