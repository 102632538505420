import React, { Fragment, forwardRef, useEffect, useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"
import { TransitionProps } from "@mui/material/transitions"
import ContainedButton from "../../basic/buttons/ContainedButton"
import SendIcon from "@mui/icons-material/Send"
import { Divider, Typography } from "@mui/material"
import GridItem from "../../basic/grid/GridItem"
import TextField from "@mui/material/TextField"
import GridContainer from "../../basic/grid/GridContainer"
import Alert from "@mui/material/Alert"
import CloseSharpIcon from "@mui/icons-material/CloseSharp"
import { useLanguage } from "../../../languageContext"
import { useMutation } from "@tanstack/react-query"
import DependencyContainer from "../../../DependencyContainer"
import { queryClient } from "../../../index"
import { Domain } from "../../../repository/DomainsRepository"

interface Props {
  openCreateDomainDialog: boolean
  setOpenCreateDomainDialog: React.Dispatch<React.SetStateAction<boolean>>
  setDomainInfoData: React.Dispatch<React.SetStateAction<Domain | undefined>>
  domainInfoData?: Domain
}

const styles = {
  domainButton: {
    textTransform: "none",
    fontWeight: "bold",
  },
  iconSend: {
    transform: "rotate(-40deg)",
    mb: "6px",
  },
  submitButton: {
    mt: "15px",
    textTransform: "none",
    fontWeight: "bold",
    mr: "-8px",
  },
  iconClose: {
    ml: "auto",
    mt: "3px",
    cursor: "pointer",
    color: "#6e6e6e",
  },
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CreateDomainModal = ({ openCreateDomainDialog, setOpenCreateDomainDialog, domainInfoData, setDomainInfoData }: Props) => {
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    if (domainInfoData) {
      setName(domainInfoData.domainName)
    } else {
      setName("")
    }
  }, [domainInfoData])

  const dependencyContainer = new DependencyContainer()
  dependencyContainer.createDependency()

  const { t } = useLanguage()

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
    setMessage("")
    setError("")
  }

  const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value)
    setMessage("")
    setError("")
  }

  const handleClose = () => {
    setOpenCreateDomainDialog(false)
    setDomainInfoData(undefined)
    setName("")
    setDescription("")
    setMessage("")
    setError("")
  }

  const addDomain = useMutation({
    mutationFn: () => dependencyContainer.dependency.domainsRepositoryApi.createDomain(name, description),
    onSuccess: async (data) => {
      setMessage("Domain successfully created !")
      setTimeout(() => {
        handleClose()
      }, 1000)
      queryClient.invalidateQueries({ queryKey: ['domainsData'] })
    },
    onError: (error: any) => {
      console.log(error)
      if (error.response?.data?.message === undefined) {
        setError(error.message)
      } else {
        setError(error.response?.data?.message)
      }
    },
  })

  const submitForm = async () => {
    if (name === "") {
      setMessage("Domain name is required !")
    }
    else if (description === "") {
      setMessage("Domain description is required !")
    }
     else {
      addDomain.mutate()
    }
  }

  return (
    <Fragment>
      <Dialog
        sx={{ "& .MuiPaper-root": { width: "570px" } }}
        open={openCreateDomainDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: "flex" }}>
          <GridItem sx={{ fontWeight: "bold" }}>{domainInfoData ? `${t("editDomain")}` : `${t("createDomain")}`}</GridItem>
          <CloseSharpIcon onClick={handleClose} sx={styles.iconClose} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <GridItem sx={{ fontSize: "15px" }}></GridItem>
        </DialogContent>
        <DialogContent>
          <GridContainer>
            <GridItem xs={4} sx={{ fontSize: "15px" }}>
              {t("domainName")}:
            </GridItem>
            <GridItem xs={8}>
              <TextField
                required
                id="domainName"
                label="domain name"
                size="small"
                fullWidth
                value={name}
                onChange={handleChangeName}
                InputLabelProps={{
                  style: { fontSize: "14px", letterSpacing: "1px" },
                }}
              />
              <Typography sx={{fontSize: "12px", mt: "3px"}}>
                * Only lowercase letters, numbers and underscores are allowed
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer sx={{ mt: "20px" }}>
            <GridItem xs={4} sx={{ fontSize: "15px" }}>
              {t("domainDesc")}:
            </GridItem>
            <GridItem xs={8}>
              <TextField
                id="domainDescription"
                label="domain description"
                multiline
                rows={5}
                value={description}
                onChange={handleChangeDescription}
                fullWidth
                InputLabelProps={{
                  style: { fontSize: "14px", letterSpacing: "1px" },
                }}
              />
              <GridContainer sx={{ height: "40px", mt: "10px" }}>
                {error === "" && (message === "Domain name is required !" || message === "Domain description is required !") && <Alert severity="warning">{message}</Alert>}
                {error === "" && message === "Domain successfully created !" && <Alert severity="success">{message}</Alert>}
                {error !== "" && <Alert severity="error">{error}</Alert>}
              </GridContainer>
            </GridItem>
          </GridContainer>
          <DialogActions>
            <ContainedButton sx={styles.submitButton} endIcon={<SendIcon sx={styles.iconSend} />} onClick={submitForm}>
              Submit form
            </ContainedButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default CreateDomainModal
