import React, { useEffect, useState } from "react"
import * as am5 from "@amcharts/amcharts5"
import * as am5map from "@amcharts/amcharts5/map"
import am5geodata_europeLow from "@amcharts/amcharts5-geodata/region/world/europeLow"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import GridItem from "../../basic/grid/GridItem"
import MapModal from "../modals/MapModal"

// GeoJSON data
// var cities = {
//   type: "FeatureCollection",
//   features: [
//     {
//       type: "Feature",
//       properties: {
//         name: "Biel/Bienne",
//       },
//       geometry: {
//         type: "Point",
//         coordinates: [7.24608, 47.13713],
//       },
//     },
//     {
//       type: "Feature",
//       properties: {
//         name: "Nis",
//       },
//       geometry: {
//         type: "Point",
//         coordinates: [21.90333, 43.32472],
//       },
//     },
//     {
//       type: "Feature",
//       properties: {
//         name: "Berlin",
//       },
//       geometry: {
//         type: "Point",
//         coordinates: [13.404954, 52.520008],
//       },
//     },
//     {
//       type: "Feature",
//       properties: {
//         name: "Vienna",
//       },
//       geometry: {
//         type: "Point",
//         coordinates: [16.363449, 48.210033],
//       },
//     },
//   ],
// }

const DashboardMap = () => {
  const [openMapDialog, setOpenMapDialog] = useState(false)
  const [mapInfoData, setMapInfoData] = useState<any>(undefined)

  useEffect(() => {
    let root = am5.Root.new("chartMap")

    // Set themes
    root.setThemes([am5themes_Animated.new(root)])

    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        projection: am5map.geoNaturalEarth1(),
      })
    )

    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_europeLow,
        exclude: ["AQ"],
      })
    )

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      interactive: true,
    })

    polygonSeries.mapPolygons.template.states.create("hover", {
      fill: am5.color(0x677935),
    })

    polygonSeries.mapPolygons.template?.adapters.add("fill", function (fill: any, target: any) {
      let countryId = target.dataItem.dataContext.id
      if (countryId === "DE" || countryId === "CH" || countryId === "RS") {
        return am5.color("#a7d6f2")
      }
      return fill
    })

    polygonSeries.mapPolygons.template.adapters.add("cursorOverStyle", function (cursor: any, target: any) {
      let countryId = target.dataItem.dataContext.id
      if (countryId === "DE" || countryId === "CH" || countryId === "RS") {
        return "pointer"
      }
      return cursor
    })

    polygonSeries.mapPolygons.template.events.on("click", (event: any) => {
      if (
        event.target.dataItem.dataContext.id === "DE" ||
        event.target.dataItem.dataContext.id === "CH" ||
        event.target.dataItem.dataContext.id === "RS"
      ) {
        setOpenMapDialog(true)
        setMapInfoData(event.target.dataItem.dataContext.name)
      }
    })

    return () => {
      if (root !== null) root.dispose()
    }
  }, [])

  return (
    <GridItem>
      <div id="chartMap" style={{ width: "100%", height: "400px" }}></div>
      <MapModal
        openMapDialog={openMapDialog}
        setOpenMapDialog={setOpenMapDialog}
        mapInfoData={mapInfoData}
        setMapInfoData={setMapInfoData}
      />
    </GridItem>
  )
}

export default DashboardMap
