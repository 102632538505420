import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import DependencyContainer from "../../DependencyContainer"
import GridItem from "../../components/basic/grid/GridItem"
import { Divider, Typography } from "@mui/material"
//import { useLanguage } from "../../languageContext"
import Search from "../../components/basic/search/Search"
import ContainedButton from "../../components/basic/buttons/ContainedButton"
import SendIcon from "@mui/icons-material/Send"
import { AppTheme } from "../../AppTheme"
import Breadcrumb from "../../components/advanced/breadcrumb/Breadcrumb"
import { APIKey } from "../../repository/ApiKeysRepository"
import ApiKeysGrid from "../../components/advanced/grids/ApiKeysGrid"
import CreateApiKeyModal from "../../components/advanced/modals/CreateApiKeyModal"
import DeleteModal from "../../components/advanced/modals/DeleteModal"
import ApiKeyInfoModal from "../../components/advanced/modals/ApiKeyInfoModal"

const styles = {
  box: {
    width: "100%",
    height: "150px",
    background: "white",
    borderLeft: "1px solid #babfc7",
    borderTop: "1px solid #babfc7",
    borderRight: "1px solid #babfc7",
  },
  dashboard: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "white",
    my: "auto",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    p: "15px 20px",
    background: AppTheme.palette.primary.dark,
  },
  flex1: {
    display: "flex",
    alignItems: "center",
    height: "80px",
  },
  iconSettings: {
    cursor: "pointer",
  },
  apikeys: {
    fontSize: "20px",
    fontWeight: "bold",
    ml: "20px",
  },
  shared: {
    color: "green",
    fontSize: "20px",
    fontWeight: "bold",
    ml: "10px",
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "100px",
    background: "green",
    mt: "4px",
    mr: "10px",
  },
  domainButton: {
    ml: "auto",
    mr: "20px",
  },
  domainButton1: {
    textTransform: "none",
    fontWeight: "bold",
  },
  iconSend: {
    transform: "rotate(-40deg)",
    mb: "6px",
  },
}

const ApiKeysPage = () => {
  const [apiKeys, setApiKeys] = useState<APIKey[]>([])
  const [searchWord, setSearchWord] = useState("")
  const [openCreateApiKeyDialog, setOpenCreateApiKeyDialog] = useState(false)
  const [openDeleteApiKeyDialog, setOpenDeleteApiKeyDialog] = useState(false)
  const [filteredData, setFilteredData] = useState<APIKey[]>([])
  const [apiKeyInfoData, setApiKeyInfoData] = useState<APIKey>()
  const [apiKeyCode, setApiKeyCode] = useState("")
  const [openApiKeyInfoDialog, setOpenApiKeyInfoDialog] = useState(false)
  const [flagForApiKey, setFlagForApiKey] = useState(false)

  //const { t } = useLanguage()
  const { subdomainName, domainName } = useParams()

  const dependencyContainer = new DependencyContainer()
  dependencyContainer.createDependency()

  useQuery({
    queryKey: ["apiKeysData"],
    queryFn: () => dependencyContainer.dependency.apiKeysRepositoryApi.apiKeys(domainName as string, subdomainName as string),
    onSuccess: (data) => {
      setApiKeys(data)
    },
    onError: (error: any) => {
      console.log(error)
    },
  })

  useEffect(() => {
    setFilteredData(apiKeys)
  }, [apiKeys])

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchWord = event.target.value
    setSearchWord(searchWord)
    const filteredList = apiKeys.filter((apiKey: APIKey) => {
      return apiKey.description.toLowerCase().includes(searchWord.toLowerCase())
    })
    setFilteredData(filteredList)
  }

  const resetSearchWord = () => {
    setSearchWord("")
    setFilteredData(apiKeys)
  }

  useEffect(() => {
    if (apiKeyCode !== "" && flagForApiKey && openCreateApiKeyDialog === false) {
      setTimeout(() => {
        setOpenApiKeyInfoDialog(true)
      }, 2500)
    }
  }, [apiKeyCode, flagForApiKey, openCreateApiKeyDialog])

  const handleClickOpenCreateApiKeyDialog = () => {
    setOpenCreateApiKeyDialog(true)
  }

  return (
    <GridItem sx={{ p: 3 }}>
      <GridItem sx={styles.box}>
        <GridItem sx={styles.flex}>
          <GridItem sx={styles.dashboard}>
            <Breadcrumb subdomain={subdomainName} domain={domainName} />
          </GridItem>
          <Search handleSearch={handleSearch} searchWord={searchWord} resetSearchWord={resetSearchWord} />
        </GridItem>
        <Divider />
        <GridItem sx={styles.flex1}>
          <Typography sx={styles.apikeys}>Api Keys</Typography>
          <GridItem sx={styles.domainButton}>
            <ContainedButton sx={styles.domainButton1} endIcon={<SendIcon sx={styles.iconSend} />} onClick={handleClickOpenCreateApiKeyDialog}>
              Create Api Key
            </ContainedButton>
          </GridItem>
        </GridItem>
      </GridItem>
      <ApiKeysGrid
        setOpenCreateApiKeyDialog={setOpenCreateApiKeyDialog}
        setApiKeyInfoData={setApiKeyInfoData}
        setOpenDeleteApiKeyDialog={setOpenDeleteApiKeyDialog}
        rowData={filteredData}
      />
      <CreateApiKeyModal
        openCreateApiKeyDialog={openCreateApiKeyDialog}
        setOpenCreateApiKeyDialog={setOpenCreateApiKeyDialog}
        setApiKeyInfoData={setApiKeyInfoData}
        apiKeyInfoData={apiKeyInfoData}
        setFlagForApiKey={setFlagForApiKey}
        setApiKeyCode={setApiKeyCode}
      />

      <DeleteModal
        openDeleteDialog={openDeleteApiKeyDialog}
        setOpenDeleteDialog={setOpenDeleteApiKeyDialog}
        infoData={apiKeyInfoData}
        setInfoData={setApiKeyInfoData}
        flag={"apiKeys"}
      />
      {apiKeyCode !== "" && flagForApiKey && openCreateApiKeyDialog === false && (
        <ApiKeyInfoModal
          openApiKeyInfoDialog={openApiKeyInfoDialog}
          setOpenApiKeyInfoDialog={setOpenApiKeyInfoDialog}
          apiKeyCode={apiKeyCode}
          setApiKeyCode={setApiKeyCode}
        />
      )}
    </GridItem>
  )
}
export default ApiKeysPage
