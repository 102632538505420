import axios from "axios"
import UsageDataRepository, { Usage } from "./UsageDataRepository"


export default class APIUsageDataRepository implements UsageDataRepository {
  async usageData(): Promise<Usage> {
    const settings: any = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")

    const data = await axios.get(`${settings.SSP_BACKEND_URL}/v1/siot/usage`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.data?.data
  }
}
