import React, { useEffect, useState } from "react"
import DomainsGrid from "../../components/advanced/grids/DomainsGrid"
import GridItem from "../../components/basic/grid/GridItem"
import { Divider, Typography } from "@mui/material"
import CreateDomainModal from "../../components/advanced/modals/CreateDomainModal"
import ContainedButton from "../../components/basic/buttons/ContainedButton"
import SendIcon from "@mui/icons-material/Send"
import Search from "../../components/basic/search/Search"
import { Domain, SubdomainFromMembership } from "../../repository/DomainsRepository"
import DependencyContainer from "../../DependencyContainer"
import { useQuery } from "@tanstack/react-query"
import { useLanguage } from "../../languageContext"
import { AppTheme } from "../../AppTheme"
import DeleteModal from "../../components/advanced/modals/DeleteModal"

const styles = {
  box: {
    width: "100%",
    height: "150px",
    background: "white",
    borderLeft: "1px solid #babfc7",
    borderTop: "1px solid #babfc7",
    borderRight: "1px solid #babfc7",
  },
  dashboard: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "white",
    my: "auto",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    p: "15px 20px",
    background: AppTheme.palette.primary.dark,
  },
  flex1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "80px",
  },
  iconSettings: {
    cursor: "pointer",
  },
  domains: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  shared: {
    color: "green",
    fontSize: "20px",
    fontWeight: "bold",
    ml: "10px",
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "100px",
    background: "green",
    mt: "4px",
    mr: "10px",
  },
  domainButton: {
    ml: "auto",
    mr: "20px",
  },
  domainButton1: {
    textTransform: "none",
    fontWeight: "bold",
  },
  iconSend: {
    transform: "rotate(-40deg)",
    mb: "6px",
  },
}

const DomainsPage = () => {
  const [openCreateDomainDialog, setOpenCreateDomainDialog] = useState(false)
  const [openDeleteDomainDialog, setOpenDeleteDomainDialog] = useState(false)
  const [domainInfoData, setDomainInfoData] = useState<Domain>()
  const [filteredData, setFilteredData] = useState<Domain[]>([])
  const [searchWord, setSearchWord] = useState("")
  const [domains, setDomains] = useState<Domain[]>([])

  const { t } = useLanguage()

  const dependencyContainer = new DependencyContainer()
  dependencyContainer.createDependency()

  useQuery({
    queryKey: ["domainsData"],
    queryFn: () => dependencyContainer.dependency.domainsRepositoryApi.memberships(),
    onSuccess: (data) => {
      setDomains(
        data.domainMemberships.map((domain: Domain) => ({
          ...domain,
          "subdomains": data.subdomainMemberships.filter((subdomain: SubdomainFromMembership) => subdomain.domainName === domain.domainName),
        }))
      )
    },
    onError: (error: any) => {
      console.log(error)
    },
  })

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchWord = event.target.value
    setSearchWord(searchWord)
    const filteredList = domains.filter((domain: Domain) => {
      return domain.domainName.toLowerCase().includes(searchWord.toLowerCase())
    })
    setFilteredData(filteredList)
  }

  const resetSearchWord = () => {
    setSearchWord("")
    setFilteredData(domains)
  }

  useEffect(() => {
    setFilteredData(domains)
  }, [domains])

  const handleClickOpenCreateDomainDialog = () => {
    setOpenCreateDomainDialog(true)
  }

  return (
    <GridItem sx={{ p: 3 }}>
      <GridItem sx={styles.box}>
        <GridItem sx={styles.flex}>
          <Typography variant="body2" sx={styles.dashboard}>
            {t("domains")}
          </Typography>
          <Search handleSearch={handleSearch} searchWord={searchWord} resetSearchWord={resetSearchWord} />
        </GridItem>
        <Divider />
        <GridItem sx={styles.flex1}>
          <GridItem sx={styles.domainButton}>
            <ContainedButton sx={styles.domainButton1} endIcon={<SendIcon sx={styles.iconSend} />} onClick={handleClickOpenCreateDomainDialog}>
              {t("createDomain")}
            </ContainedButton>
          </GridItem>
        </GridItem>
      </GridItem>
      <DomainsGrid
        setOpenCreateDomainDialog={setOpenCreateDomainDialog}
        setDomainInfoData={setDomainInfoData}
        setOpenDeleteDomainDialog={setOpenDeleteDomainDialog}
        rowData={filteredData}
      />
      <CreateDomainModal
        openCreateDomainDialog={openCreateDomainDialog}
        setOpenCreateDomainDialog={setOpenCreateDomainDialog}
        domainInfoData={domainInfoData}
        setDomainInfoData={setDomainInfoData}
      />
      <DeleteModal
        openDeleteDialog={openDeleteDomainDialog}
        setOpenDeleteDialog={setOpenDeleteDomainDialog}
        infoData={domainInfoData}
        setInfoData={setDomainInfoData}
        flag={"domains"}
      />
    </GridItem>
  )
}
export default DomainsPage
