import React from "react"
import { IHeaderParams } from "ag-grid-community"

const CenterHeader = ({ displayName }: IHeaderParams) => {
  return (
    <div style={{ margin: "auto" }}>
      <span>{displayName}</span>
    </div>
  )
}

export default CenterHeader
