import React, { Fragment, forwardRef, useEffect, useState } from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"
import { TransitionProps } from "@mui/material/transitions"
import ContainedButton from "../../basic/buttons/ContainedButton"
import SendIcon from "@mui/icons-material/Send"
import { Divider } from "@mui/material"
import GridItem from "../../basic/grid/GridItem"
import TextField from "@mui/material/TextField"
import GridContainer from "../../basic/grid/GridContainer"
import Alert from "@mui/material/Alert"
import CloseSharpIcon from "@mui/icons-material/CloseSharp"
//import { useLanguage } from "../../../languageContext"
import { useMutation } from "@tanstack/react-query"
import DependencyContainer from "../../../DependencyContainer"
import { queryClient } from "../../../index"
import { APIKey } from "../../../repository/ApiKeysRepository"
import { useParams } from "react-router-dom"

interface Props {
  openCreateApiKeyDialog: boolean
  setOpenCreateApiKeyDialog: React.Dispatch<React.SetStateAction<boolean>>
  setApiKeyInfoData: React.Dispatch<React.SetStateAction<APIKey | undefined>>
  apiKeyInfoData?: APIKey
  setFlagForApiKey: React.Dispatch<React.SetStateAction<boolean>>
  setApiKeyCode: any
}

const styles = {
  domainButton: {
    textTransform: "none",
    fontWeight: "bold",
  },
  iconSend: {
    transform: "rotate(-40deg)",
    mb: "6px",
  },
  submitButton: {
    mt: "15px",
    textTransform: "none",
    fontWeight: "bold",
    mr: "-8px",
  },
  iconClose: {
    ml: "auto",
    mt: "3px",
    cursor: "pointer",
    color: "#6e6e6e",
  },
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CreateApiKeyModal = ({setApiKeyCode, setFlagForApiKey, openCreateApiKeyDialog, setOpenCreateApiKeyDialog, apiKeyInfoData, setApiKeyInfoData }: Props) => {
  const [description, setDescription] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    if (apiKeyInfoData) {
      setDescription(apiKeyInfoData.description)
    } else {
      setDescription("")
    }
  }, [apiKeyInfoData])

  const dependencyContainer = new DependencyContainer()
  dependencyContainer.createDependency()

  //const { t } = useLanguage()
  const { subdomainName, domainName } = useParams() as { subdomainName: string; domainName: string }

  const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value)
    setMessage("")
    setError("")
  }

  const handleClose = () => {
    setOpenCreateApiKeyDialog(false)
    //setApiKeyInfoData(undefined)
    setDescription("")
    setMessage("")
    setError("")
    setFlagForApiKey(true)
  }

  const addApiKey = useMutation({
    mutationFn: () => dependencyContainer.dependency.apiKeysRepositoryApi.createApiKey(domainName, subdomainName, description),
    onSuccess: async (data) => {
      setApiKeyCode(data?.apiKey)
      setMessage("Api key successfully created !")
      setTimeout(() => {
        handleClose()
      }, 1000)
      queryClient.invalidateQueries({ queryKey: ["apiKeysData"] })
    },
    onError: (error: any) => {
      console.log(error)
      if (error.response?.data?.message === undefined) {
        setError(error.message)
      } else {
        setError(error.response?.data?.message)
      }
    },
  })

  const editApiKey = useMutation({
    mutationFn: () =>
      dependencyContainer.dependency.apiKeysRepositoryApi.updateApiKey(domainName, subdomainName, description, apiKeyInfoData?.shortToken as string),
    onSuccess: async (data) => {
      setMessage("Api key successfully edited !")
      setTimeout(() => {
        handleClose()
      }, 1000)
      queryClient.invalidateQueries({ queryKey: ["apiKeysData"] })
    },
    onError: (error: any) => {
      console.log(error)
      if (error.response?.data?.message === undefined) {
        setError(error.message)
      } else {
        setError(error.response?.data?.message)
      }
    },
  })

  const addNewApiKey = async () => {
    if (description === "") {
      setMessage("Api key description is required !")
    } else {
      addApiKey.mutate()
    }
  }

  const updateApiKey = async () => {
    if (description === "") {
      setMessage("Api key description is required !")
    } else {
      editApiKey.mutate()
    }
  }

  return (
    <Fragment>
      <Dialog
        sx={{ "& .MuiPaper-root": { width: "500px" } }}
        open={openCreateApiKeyDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: "flex" }}>
          <GridItem sx={{ fontWeight: "bold" }}>{apiKeyInfoData ? "Edit Api Key" : "Create Api Key"}</GridItem>
          <CloseSharpIcon onClick={handleClose} sx={styles.iconClose} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <GridItem sx={{ fontSize: "15px" }}></GridItem>
        </DialogContent>
        <DialogContent>
          <GridContainer>
            <GridItem xs={4} sx={{ fontSize: "15px" }}>
              Enter Api Key description:
            </GridItem>
            <GridItem xs={8}>
              <TextField
                id="apiKeyDescription"
                label="Api Key description"
                multiline
                rows={5}
                value={description}
                onChange={handleChangeDescription}
                fullWidth
                InputLabelProps={{
                  style: { fontSize: "14px", letterSpacing: "1px" },
                }}
              />
              <GridContainer sx={{ height: "40px", mt: "10px" }}>
                {error === "" && message === "Api key description is required !" && <Alert severity="warning">{message}</Alert>}
                {error === "" && message === "Api key successfully created !" && <Alert severity="success">{message}</Alert>}
                {error === "" && message === "Api key successfully edited !" && <Alert severity="success">{message}</Alert>}
                {error !== "" && <Alert severity="error">{error}</Alert>}
              </GridContainer>
            </GridItem>
          </GridContainer>
          <DialogActions>
            <ContainedButton
              sx={styles.submitButton}
              endIcon={<SendIcon sx={styles.iconSend} />}
              onClick={() => {
                apiKeyInfoData ? updateApiKey() : addNewApiKey()
              }}
            >
              Submit form
            </ContainedButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default CreateApiKeyModal
