import axios from "axios"
import DomainsRepository, { Memberships, CreatedDomain } from "./DomainsRepository"

export default class APIDomainsRepository implements DomainsRepository {

  async memberships(): Promise<Memberships> {
    const settings: any = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")

    const data = await axios.get(`${settings.SSP_BACKEND_URL}/v1/siot/memberships`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.data?.data
  }

  async createDomain(name: string, description: string): Promise<CreatedDomain> {
    const settings: any = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")

    const body = {
			'name': name,
			'description': description,
		}

    const data = await axios.post(`${settings.SSP_BACKEND_URL}/v1/siot/domain`,body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.data?.data
  }

  async deleteDomain(domain: string): Promise<void> {
    const settings: any = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")

    await axios.delete(`${settings.SSP_BACKEND_URL}/v1/siot/domain/${domain}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }
}
