import React, { Fragment, useState } from "react"
import { Link } from "react-router-dom"
import List from "@mui/material/List"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { useLocation } from "react-router-dom"
import { Collapse, Grid, ListItemButton, SvgIconProps, Typography } from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import GridItem from "../../basic/grid/GridItem"
import { useLanguage } from "../../../languageContext"
import { AppTheme } from "../../../AppTheme"

interface Route {
  name: string
  route: string
  icon: React.ReactElement<SvgIconProps>
}

interface Element {
  name: string
  route: string
  icon: React.ReactElement<SvgIconProps>
  subitems?: Route[]
}

interface Props {
  routes: Element[]
  open: boolean
}

const styles = {
  activeRoute: {
    backgroundColor: "",
    color: AppTheme.palette.primary.main,
    "&:hover": {
      backgroundColor: "",
      transition: ".4s ease",
      color: AppTheme.palette.primary.main,
    },
  },
  activeSubRoute: {
    backgroundColor: AppTheme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: AppTheme.palette.primary.main,
      transition: ".4s ease",
      color: "white",
    },
  },
  route: {
    color: "white",
    "&:hover": {
      backgroundColor: "",
      transition: ".4s ease",
      color: "white",
    },
  },
  route2: {
    color: "#6b6b6b",
    cursor: "not-allowed",
    "&:hover": {
      backgroundColor: "",
      transition: ".4s ease",
      color: "#6b6b6b",
    },
  },
  subRoute: {
    color: "white",
    "&:hover": {
      backgroundColor: AppTheme.palette.primary.main,
      transition: ".4s ease",
      color: "white",
    },
  },
  activeIcon: {
    paddingLeft: "3px",
    color: AppTheme.palette.primary.main,
  },
  icon: {
    paddingLeft: "3px",
    color: "white",
  },
  icon2: {
    paddingLeft: "3px",
    color: "#6b6b6b",
  },
  header: {
    backgroundColor: "transparent",
    color: "#a8acb5",
    fontSize: "13px",
    ml: "-7px",
    textTransform: "uppercase",
    mb: "40px",
  },
}

const SidebarItems = ({ routes, open }: Props) => {
  const [openIndex, setOpenIndex] = useState(-1)
  const location = useLocation()
  const { t } = useLanguage()

  const handleClick = (index: number) => {
    setOpenIndex(openIndex === index ? -1 : index)
  }

  const isActiveRoute = (route: string) => {
    return location.pathname === route
  }

  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        // subheader={
        //   <ListSubheader sx={styles.header}  component="div" id="nested-list-subheader">
        //     {/* MAIN */}
        //   </ListSubheader>
        // }
      >
        {routes.map((item: Element, index: number) => (
          <Fragment key={index}>
            {item.subitems ? (
              <Fragment>
                <ListItemButton onClick={() => handleClick(index)} sx={isActiveRoute(item.route) ? styles.activeRoute : styles.route}>
                  <ListItemIcon sx={isActiveRoute(item.route) ? styles.activeIcon : styles.icon}>{item.icon}</ListItemIcon>
                  <ListItemText
                    sx={{ opacity: open ? 1 : 0 }}
                    primary={
                      <Typography variant="body2" style={{ fontSize: "14px" }}>
                        {t(item.name)}
                      </Typography>
                    }
                  />
                  <GridItem sx={{ opacity: open ? 1 : 0, position: "absolute", left: "195px", top: "10px" }}>
                    {open ? openIndex === index ? <ExpandLess /> : <ExpandMore /> : null}
                  </GridItem>
                </ListItemButton>
                <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subitems.map((subroute: Route, subIndex: number) => (
                      <ListItemButton
                        component={Link}
                        to={subroute.route}
                        key={`${index}-${subIndex}`}
                        sx={isActiveRoute(subroute.route) ? styles.activeSubRoute : styles.subRoute}
                      >
                        <ListItemIcon sx={{ color: "white", pl: "5px" }}>{subroute.icon}</ListItemIcon>
                        <ListItemText
                          sx={{ opacity: open ? 1 : 0 }}
                          primary={
                            <Typography variant="body2" style={{ fontSize: "14px" }}>
                              {t(subroute.name)}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </Fragment>
            ) : (
              <ListItemButton
                component={item.route === "/settings" || item.route === "/documentation" || item.route === "/support" ? Grid : Link}
                to={item.route}
                sx={
                  isActiveRoute(item.route)
                    ? styles.activeRoute
                    : item.route === "/settings" || item.route === "/documentation" || item.route === "/support"
                    ? styles.route2
                    : styles.route
                }
              >
                <ListItemIcon
                  sx={
                    isActiveRoute(item.route)
                      ? styles.activeIcon
                      : item.route === "/settings" || item.route === "/documentation" || item.route === "/support"
                      ? styles.icon2
                      : styles.icon
                  }
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{ opacity: open ? 1 : 0 }}
                  primary={
                    <Typography variant="body2" style={{ fontSize: "14px" }}>
                      {t(item.name)}
                    </Typography>
                  }
                />
              </ListItemButton>
            )}
          </Fragment>
        ))}
      </List>
    </>
  )
}

export default SidebarItems
