import React, { useEffect } from "react"
import GridItem from "../../basic/grid/GridItem"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import * as am5 from "@amcharts/amcharts5"
//import * as am5xy from "@amcharts/amcharts5/xy"
import * as am5percent from "@amcharts/amcharts5/percent"
import { Domain } from "../../../repository/DomainsRepository"

interface Props {
  data: Domain[]
}

const DomainsDonut = ({ data }: Props) => {

  useEffect(() => {
    let root = am5.Root.new("domainsByStatus")

    const myTheme = am5.Theme.new(root)

    myTheme.rule("Label").setAll({
      fontSize: "13px",
      fontWeight: "bold",
    })

    root.setThemes([am5themes_Animated.new(root), myTheme])

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5.percent(70),
        innerRadius: am5.percent(60),
      })
    )

    // Define data
    let donutData = [
      {
        domain: "available",
        number: data?.length,
      },
    ]

    // Create series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        valueField: "number",
        categoryField: "domain",
        alignLabels: false,
      })
    )

    // series.labels.template.set("visible", true)
    series.ticks.template.set("visible", false)
    series.slices.template.set("tooltipText", "{category}: {value}")
    // series.labels.template.set("text", "{category}: {value}")

    series.labels.template.setAll({
      visible: true,
      radius: 25,
      inside: true,
      text: "{valuePercentTotal.formatNumber('0.00')}%",
      textType: "circular",
      fill: am5.color(0xffffff),
    })

    series.get("colors")?.set("colors", [am5.color(0x29b6f6), am5.color(0xef4544), am5.color(0x999999), am5.color(0xa87f20)])

    series.data.setAll(donutData)

    const legend = root.container.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        // marginTop: 15,
        marginBottom: 15,
        // useDefaultMarker: true,
        // layout: root.horizontalLayout
      })
    )

    legend.labels.template.setAll({
      // fontSize: 16,
      fontWeight: "300",
    })

    legend.valueLabels.template.setAll({
      // fontSize: 16,
      fontWeight: "600",
      visible: false,
    })

    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
    })

    legend.data.setAll(series.dataItems)

    return () => {
      root.dispose()
    }
  }, [data])

  return (
    <>
      <GridItem style={{ width: "100%", height: "100%" }}>
        <div id="domainsByStatus" style={{ width: "100%", height: "100%" }}></div>
      </GridItem>
    </>
  )
}
export default DomainsDonut
