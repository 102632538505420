import React, { Fragment, forwardRef, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"
import { TransitionProps } from "@mui/material/transitions"
import ContainedButton from "../../basic/buttons/ContainedButton"
import SendIcon from "@mui/icons-material/Send"
import { Divider, Typography } from "@mui/material"
import GridItem from "../../basic/grid/GridItem"
import TextField from "@mui/material/TextField"
import GridContainer from "../../basic/grid/GridContainer"
import Alert from "@mui/material/Alert"
import CloseSharpIcon from "@mui/icons-material/CloseSharp"
//import { useLanguage } from "../../../languageContext"
import { useMutation } from "@tanstack/react-query"
import DependencyContainer from "../../../DependencyContainer"
import { queryClient } from "../../../index"
import { Subdomain } from "../../../repository/DomainsRepository"

interface Props {
  openCreateSubdomainDialog: boolean
  setOpenCreateSubdomainDialog: React.Dispatch<React.SetStateAction<boolean>>
  setSubdomainInfoData: React.Dispatch<React.SetStateAction<Subdomain | undefined>>
  subdomainInfoData?: Subdomain
}

const styles = {
  domainButton: {
    textTransform: "none",
    fontWeight: "bold",
  },
  iconSend: {
    transform: "rotate(-40deg)",
    mb: "6px",
  },
  submitButton: {
    mt: "15px",
    textTransform: "none",
    fontWeight: "bold",
    mr: "-8px",
  },
  iconClose: {
    ml: "auto",
    mt: "3px",
    cursor: "pointer",
    color: "#6e6e6e",
  },
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CreateSubdomainModal = ({ openCreateSubdomainDialog, setOpenCreateSubdomainDialog, subdomainInfoData, setSubdomainInfoData }: Props) => {
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState("")

  const { domainName } = useParams()

  useEffect(() => {
    if (subdomainInfoData) {
      setName(subdomainInfoData.name)
    } else {
      setName("")
    }
  }, [subdomainInfoData])

  const dependencyContainer = new DependencyContainer()
  dependencyContainer.createDependency()

  //const { t } = useLanguage()

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
    setMessage("")
    setError("")
  }

  const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value)
    setMessage("")
    setError("")
  }

  const handleClose = () => {
    setOpenCreateSubdomainDialog(false)
    setSubdomainInfoData(undefined)
    setName("")
    setDescription("")
    setMessage("")
    setError("")
  }

  const addSubdomain = useMutation({
    mutationFn: () => dependencyContainer.dependency.subdomainsRepositoryApi.createSubdomain(domainName as string, name, description),
    onSuccess: async (data) => {
      setMessage("Subdomain successfully created !")
      setTimeout(() => {
        handleClose()
      }, 1000)
      queryClient.invalidateQueries({ queryKey: ['subdomainsData'] })
    },
    onError: (error: any) => {
      console.log(error)
      if (error.response?.data?.message === undefined) {
        setError(error.message)
      } else {
        setError(error.response?.data?.message)
      }
    },
  })

  const submitForm = async () => {
    if (name === "") {
      setMessage("Subdomain name is required !")
    }
    else if (description === "") {
      setMessage("Subdomain description is required !")
    }
     else {
      addSubdomain.mutate()
    }
  }

  return (
    <Fragment>
      <Dialog
        sx={{ "& .MuiPaper-root": { width: "570px" } }}
        open={openCreateSubdomainDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: "flex" }}>
          <GridItem sx={{ fontWeight: "bold" }}>{subdomainInfoData ? "Edit Subdomain" : "Create Subdomain"}</GridItem>
          <CloseSharpIcon onClick={handleClose} sx={styles.iconClose} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <GridItem sx={{ fontSize: "15px" }}></GridItem>
        </DialogContent>
        <DialogContent>
          <GridContainer>
            <GridItem xs={4} sx={{ fontSize: "15px" }}>
              Subdomain name:
            </GridItem>
            <GridItem xs={8}>
              <TextField
                required
                id="subdomainName"
                label="subdomain name"
                size="small"
                fullWidth
                value={name}
                onChange={handleChangeName}
                InputLabelProps={{
                  style: { fontSize: "14px", letterSpacing: "1px" },
                }}
              />
              <Typography sx={{fontSize: "12px", mt: "3px"}}>
                * Only lowercase letters, numbers and underscores are allowed
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer sx={{ mt: "20px" }}>
            <GridItem xs={4} sx={{ fontSize: "15px" }}>
              Subdomain description:
            </GridItem>
            <GridItem xs={8}>
              <TextField
                id="subdomainDescription"
                label="subdomain description"
                multiline
                rows={5}
                value={description}
                onChange={handleChangeDescription}
                fullWidth
                InputLabelProps={{
                  style: { fontSize: "14px", letterSpacing: "1px" },
                }}
              />
              <GridContainer sx={{ height: "40px", mt: "10px" }}>
                {error === "" && (message === "Subdomain name is required !" || message === "Subdomain description is required !") && <Alert severity="warning">{message}</Alert>}
                {error === "" && message === "Subdomain successfully created !" && <Alert severity="success">{message}</Alert>}
                {error !== "" && <Alert severity="error">{error}</Alert>}
              </GridContainer>
            </GridItem>
          </GridContainer>
          <DialogActions>
            <ContainedButton sx={styles.submitButton} endIcon={<SendIcon sx={styles.iconSend} />} onClick={submitForm}>
              Submit form
            </ContainedButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default CreateSubdomainModal
