import APISubdomainsRepository from './repository/APISubdomainsRepository'
import APIDomainsRepository from './repository/APIDomainsRepository'
import SubdomainsRepository from './repository/SubdomainsRepository'
import DomainsRepository from './repository/DomainsRepository'
import APIApiKeysRepository from './repository/APIApiKeysRepository'
import ApiKeysRepository from './repository/ApiKeysRepository'
import APIUsageDataRepository from './repository/APIUsageDataRepository'
import UsageDataRepository from './repository/UsageDataRepository'

export interface Dependency {
  // keycloak: AppKeycloak
  subdomainsRepositoryApi: SubdomainsRepository
  domainsRepositoryApi: DomainsRepository
	apiKeysRepositoryApi: ApiKeysRepository
	usageDataRepositoryApi: UsageDataRepository
}

export default class DependencyContainer {

	private _dependency!: Dependency

	createDependency(): void {

		// const keycloak = AppKeycloak.createAppKeycloak('/keycloak.json')

		this._dependency = {
			// keycloak,
			subdomainsRepositoryApi: new APISubdomainsRepository(),
			domainsRepositoryApi: new APIDomainsRepository(),
			apiKeysRepositoryApi: new APIApiKeysRepository(),
			usageDataRepositoryApi: new APIUsageDataRepository()
		}
		//console.log(this._dependency)
	}
	get dependency(): Dependency {
		return this._dependency
	}
}
