import React, { forwardRef, useState } from "react"
import { useParams } from "react-router-dom"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"
import { TransitionProps } from "@mui/material/transitions"
import ContainedButton from "../../basic/buttons/ContainedButton"
import { Alert, Divider } from "@mui/material"
import GridItem from "../../basic/grid/GridItem"
import { ReactComponent as Check } from "../../../assets/icons/check.svg"
import { ReactComponent as Close } from "../../../assets/icons/closeWhite.svg"
import CloseSharpIcon from "@mui/icons-material/CloseSharp"
import { useMutation } from "@tanstack/react-query"
import DependencyContainer from "../../../DependencyContainer"
import { queryClient } from "../../../index"

interface Props {
  openDeleteDialog: boolean
  setOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>
  flag: string
  setInfoData: React.Dispatch<React.SetStateAction<any | undefined>>
  infoData: any | undefined
}

const styles = {
  domainButton: {
    textTransform: "none",
    fontWeight: "bold",
  },
  iconSend: {
    transform: "rotate(-40deg)",
    mb: "6px",
  },
  buttonCancel: {
    mt: "5px",
    textTransform: "none",
    fontWeight: "bold",
    mr: "18px",
    backgroundColor: "#ef4544",
    "&:hover": {
      backgroundColor: "#bf3636",
    },
  },
  buttonDelete: {
    mt: "5px",
    textTransform: "none",
    fontWeight: "bold",
    mr: "-8px",
  },
  iconClose: {
    ml: "auto",
    mt: "3px",
    cursor: "pointer",
    color: "#6e6e6e",
  },
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DeleteModal = ({ infoData, setInfoData, openDeleteDialog, setOpenDeleteDialog, flag }: Props) => {
  const [error, setError] = useState("")
  const { domainName, subdomainName } = useParams()

  const dependencyContainer = new DependencyContainer()
  dependencyContainer.createDependency()

  const deleteDomain = useMutation({
    mutationFn: () => dependencyContainer.dependency.domainsRepositoryApi.deleteDomain(infoData?.domainName as string),
    onSuccess: async (data) => {
      handleClose()
      queryClient.invalidateQueries({ queryKey: ["domainsData"] })
    },
    onError: (error: any) => {
      console.log(error)
      if (error.response?.data?.message === undefined) {
        setError(error.message)
      } else {
        setError(error.response?.data?.message)
      }
    },
  })

  const deleteSubdomain = useMutation({
    mutationFn: () => dependencyContainer.dependency.subdomainsRepositoryApi.deleteSubdomain(domainName as string, infoData?.name as string),
    onSuccess: async (data) => {
      handleClose()
      queryClient.invalidateQueries({ queryKey: ["subdomainsData"] })
    },
    onError: (error: any) => {
      console.log(error)
      if (error.response?.data?.message === undefined) {
        setError(error.message)
      } else {
        setError(error.response?.data?.message)
      }
    },
  })

  const deleteApiKey = useMutation({
    mutationFn: () =>
      dependencyContainer.dependency.apiKeysRepositoryApi.deleteApiKey(domainName as string, subdomainName as string, infoData?.shortToken as string),
    onSuccess: async (data) => {
      handleClose()
      queryClient.invalidateQueries({ queryKey: ["apiKeysData"] })
    },
    onError: (error: any) => {
      console.log(error)
      if (error.response?.data?.message === undefined) {
        setError(error.message)
      } else {
        setError(error.response?.data?.message)
      }
    },
  })

  const handleClose = () => {
    setOpenDeleteDialog(false)
    setInfoData(undefined)
  }

  const mutateFunction = () => {
    if (flag === "domains") {
      deleteDomain.mutate()
    } else if (flag === "subdomains") {
      deleteSubdomain.mutate()
    } else if (flag === "apiKeys") {
      deleteApiKey.mutate()
    }
  }

  const itemName = () => {
    if (flag === "domains") {
      return "domain"
    } else if (flag === "subdomains") {
      return "subdomain"
    } else if (flag === "apiKeys") {
      return "Api Key"
    }
  }

  const itemDataName = () => {
    if (flag === "domains") {
      return infoData?.domainName
    } else if (flag === "subdomains") {
      return infoData?.name
    } else if (flag === "apiKeys") {
      return infoData?.shortToken
    }
  }

  const description = () => {
    if (flag === "domains") {
      return "Delete domain"
    } else if (flag === "subdomains") {
      return "Delete subdomain"
    } else if (flag === "apiKeys") {
      return "Delete Api Key"
    }
  }

  return (
    <div>
      <Dialog
        open={openDeleteDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: "flex" }}>
          <GridItem sx={{ fontWeight: "bold" }}>{description()}</GridItem>
          <CloseSharpIcon onClick={handleClose} sx={styles.iconClose} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <GridItem sx={{ fontSize: "15px" }}>
            Warning: This action will delete the {itemName()} <b>{itemDataName()}</b> and the associated data permanently and cannot be undone.
          </GridItem>
        </DialogContent>
        <Divider />
        <GridItem sx={{ height: "40px", mt: "10px", px: "20px" }}>{error !== "" && <Alert severity="error">{error}</Alert>}</GridItem>
        <DialogContent>
          <DialogActions>
            <ContainedButton sx={styles.buttonCancel} onClick={handleClose} endIcon={<Close />}>
              No
            </ContainedButton>
            <ContainedButton sx={styles.buttonDelete} onClick={mutateFunction} endIcon={<Check />}>
              Yes
            </ContainedButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default DeleteModal
