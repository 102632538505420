import axios from "axios"
import SubdomainsRepository, { CreatedSubdomain, Subdomain } from "./SubdomainsRepository"

export default class APISubdomainsRepository implements SubdomainsRepository {

  async getSubdomains(domain: string): Promise<Subdomain[]> {
    const settings: any = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")

    const data = await axios.get(`${settings.SSP_BACKEND_URL}/v1/siot/${domain}/subdomains`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.data?.data
  }

  async createSubdomain(domain: string, name: string, description: string): Promise<CreatedSubdomain> {
    const settings: any = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")

    const body = {
			'name': name,
			'description': description,
		}

    const data = await axios.post(`${settings.SSP_BACKEND_URL}/v1/siot/${domain}/subdomain`,body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.data?.data
  }

  async deleteSubdomain(domain: string, subdomain: string): Promise<void> {
    const settings: any = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")

    await axios.delete(`${settings.SSP_BACKEND_URL}/v1/siot/${domain}/subdomain/${subdomain}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

}
