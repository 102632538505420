import React, { Fragment, forwardRef, useEffect } from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"
import { TransitionProps } from "@mui/material/transitions"
import { Divider } from "@mui/material"
import GridItem from "../../basic/grid/GridItem"
import CloseSharpIcon from "@mui/icons-material/CloseSharp"
import { useLanguage } from "../../../languageContext"
import * as am5 from "@amcharts/amcharts5"
import * as am5map from "@amcharts/amcharts5/map"
import am5geodata_europeLow from "@amcharts/amcharts5-geodata/region/world/europeLow"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

interface Props {
  openMapDialog: boolean
  setOpenMapDialog: React.Dispatch<React.SetStateAction<boolean>>
  setMapInfoData: React.Dispatch<React.SetStateAction<any | undefined>>
  mapInfoData?: any
}

const styles = {
  domainButton: {
    textTransform: "none",
    fontWeight: "bold",
  },
  iconSend: {
    transform: "rotate(-40deg)",
    mb: "6px",
  },
  submitButton: {
    mt: "15px",
    textTransform: "none",
    fontWeight: "bold",
    mr: "-8px",
  },
  iconClose: {
    ml: "auto",
    mt: "3px",
    cursor: "pointer",
    color: "#6e6e6e",
  },
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const MapModal = ({ openMapDialog, setOpenMapDialog, mapInfoData, setMapInfoData }: Props) => {
  const { t } = useLanguage()
  //const { subdomainName, domainName } = useParams() as { subdomainName: string; domainName: string }

  const handleClose = () => {
    setOpenMapDialog(false)
    setMapInfoData(undefined)
  }

  useEffect(() => {
    const mapCountryId = () => {
      if (mapInfoData === "Germany") return "DE"
      else if (mapInfoData === "Switzerland") return "CH"
      else if (mapInfoData === "Serbia") return "RS"
    }

    if (openMapDialog) {
      let root = am5.Root.new("chartMap1")

      // Set themes
      root.setThemes([am5themes_Animated.new(root)])

      let chart = root.container.children.push(
        am5map.MapChart.new(root, {
          panX: "rotateX",
          projection: am5map.geoNaturalEarth1(),
        })
      )

      if (mapInfoData === "Germany") {
        setTimeout(() => {
          chart.zoomToGeoPoint({ longitude: 10, latitude: 51 }, 5)
        }, 1000)
      }
      if (mapInfoData === "Switzerland") {
        setTimeout(() => {
          chart.zoomToGeoPoint({ longitude: 7, latitude: 46 }, 12)
        }, 1000)
      }
      if (mapInfoData === "Serbia") {
        setTimeout(() => {
          chart.zoomToGeoPoint({ longitude: 21, latitude: 43 }, 10)
        }, 1000)
      }

      let polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_europeLow,
          exclude: ["AQ"],
        })
      )

      polygonSeries.mapPolygons.template.setAll({
        tooltipText: "{name}",
        interactive: true,
      })

      polygonSeries.mapPolygons.template.states.create("hover", {
        
        fill: am5.color(0x677935),
      })

      polygonSeries.mapPolygons.template?.adapters.add("fill", function (fill: any, target: any) {
        let countryId = target.dataItem.dataContext.id
        if (countryId === mapCountryId()) {
          return am5.color("#a7d6f2")
        }
        return fill
      })

      polygonSeries.mapPolygons.template.adapters.add("cursorOverStyle", function (cursor: any, target: any) {
        let countryId = target.dataItem.dataContext.id
        if (countryId === mapCountryId()) {
          return "pointer"
        }
        return cursor
      })

      return () => {
        if (root !== null) root.dispose()
      }
    }
  }, [mapInfoData, openMapDialog, setMapInfoData, setOpenMapDialog])

  return (
    <Fragment>
      <Dialog
        sx={{ "& .MuiPaper-root": { width: "800px" } }}
        open={openMapDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ display: "flex" }}>
          <GridItem sx={{ fontWeight: "bold" }}>{mapInfoData}</GridItem>
          <CloseSharpIcon onClick={handleClose} sx={styles.iconClose} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div id="chartMap1" style={{ width: "100%", height: "350px" }}></div>
        </DialogContent>
        <DialogContent>
          <DialogActions></DialogActions>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default MapModal
