import React, { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import DependencyContainer from "../../DependencyContainer"
import { APIKey } from "../../repository/ApiKeysRepository"
import { useParams } from "react-router-dom"
import GridItem from "../../components/basic/grid/GridItem"
import { Divider, Typography } from "@mui/material"
import GridContainer from "../../components/basic/grid/GridContainer"
import { AppTheme } from "../../AppTheme"
import Breadcrumb from "../../components/advanced/breadcrumb/Breadcrumb"

const styles = {
  dashboard: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "white",
    my: "auto",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    p: "15px 20px",
    background: AppTheme.palette.primary.dark,
  },
  outlineBox: {
    flex: 1,
    flexWrap: "wrap",
    minWidth: { xs: "100%", sm: "250px", md: "250px", lg: "250px", xl: "250px" },
    width: { xs: "100%" },
  },
  outlineBoxWider: {
    flex: 2,
    flexWrap: "wrap",
    minWidth: { xs: "100%", sm: "500px", md: "500px", lg: "500px", xl: "500px" },
    width: { xs: "100%" },
  },
  box: {
    background: "white",
    border: "1px solid #babfc7",
    backgroundColor: "#eeeeee",
  },
  headingTypography: {
    fontSize: "15px",
  },
  valueTypography: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  heading: {
    p: "15px 20px",
  },
  content: {
    pt: "15px",
    pl: "20px",
    alignItems: "center",
    minHeight: "70px",
  },
}

const ApiKeyPage = () => {
  const [apiKey, setApiKey] = useState<APIKey>()

  const { domainName, subdomainName, apiKeyId } = useParams()

  const dependencyContainer = new DependencyContainer()
  dependencyContainer.createDependency()

  useQuery({
    queryKey: ["apiKeyData"],
    queryFn: () => dependencyContainer.dependency.apiKeysRepositoryApi.getApiKey(domainName as string, subdomainName as string, apiKeyId as string),
    onSuccess: (data) => {
      setApiKey(data)
    },
    onError: (error: any) => {
      console.log(error)
    },
  })

  return (
    <GridItem sx={{ p: 3 }}>
      <GridItem sx={styles.flex}>
        <GridItem sx={styles.dashboard}>
          <Breadcrumb subdomain={subdomainName} domain={domainName} apiKeyId={apiKeyId} />
        </GridItem>
      </GridItem>

      <GridContainer sx={{ gap: "15px", mb: "15px", mt: "15px" }}>
        <GridItem sx={styles.outlineBox}>
          <GridItem sx={styles.box}>
            <GridItem sx={styles.heading}>
              <Typography variant="body2" sx={styles.headingTypography}>
                Description
              </Typography>
            </GridItem>
            <Divider sx={{ mt: "-5px" }} />
            <GridItem sx={styles.content}>
              <Typography variant="body2" sx={styles.valueTypography}>
                {apiKey?.description}
              </Typography>
            </GridItem>
          </GridItem>
        </GridItem>
        <GridItem sx={styles.outlineBox}>
          <GridItem sx={styles.box}>
            <GridItem sx={styles.heading}>
              <Typography variant="body2" sx={styles.headingTypography}>
                Short Token
              </Typography>
            </GridItem>
            <Divider sx={{ mt: "-5px" }} />
            <GridItem sx={styles.content}>
              <Typography variant="body2" sx={styles.valueTypography}>
                {apiKey?.shortToken}
              </Typography>
            </GridItem>
          </GridItem>
        </GridItem>
      </GridContainer>

      <GridContainer sx={{ gap: "15px", mb: "15px", mt: "15px" }}>
        <GridItem sx={styles.outlineBox}>
          <GridItem sx={styles.box}>
            <GridItem sx={styles.heading}>
              <Typography variant="body2" sx={styles.headingTypography}>
                Key Role
              </Typography>
            </GridItem>
            <Divider sx={{ mt: "-5px" }} />
            <GridItem sx={styles.content}>
              <Typography variant="body2" sx={styles.valueTypography}>
                {apiKey?.roleKey}
              </Typography>
            </GridItem>
          </GridItem>
        </GridItem>
        <GridItem sx={styles.outlineBox}>
          <GridItem sx={styles.box}>
            <GridItem sx={styles.heading}>
              <Typography variant="body2" sx={styles.headingTypography}>
                Expire at
              </Typography>
            </GridItem>
            <Divider sx={{ mt: "-5px" }} />
            <GridItem sx={styles.content}>
              <Typography variant="body2" sx={styles.valueTypography}>
                {new Date(apiKey?.expiration as number).toLocaleDateString("de-CH", { day: "2-digit", month: "2-digit", year: "numeric" })}
              </Typography>
            </GridItem>
          </GridItem>
        </GridItem>
      </GridContainer>

      <GridContainer sx={{ gap: "15px", mb: "15px", mt: "15px" }}>
        <GridItem sx={styles.outlineBox}>
          <GridItem sx={styles.box}>
            <GridItem sx={styles.heading}>
              <Typography variant="body2" sx={styles.headingTypography}>
                Created At
              </Typography>
            </GridItem>
            <Divider sx={{ mt: "-5px" }} />
            <GridItem sx={styles.content}>
              <Typography variant="body2" sx={styles.valueTypography}>
                {new Date(apiKey?.createdAt as string).toLocaleDateString("de-CH", { day: "2-digit", month: "2-digit", year: "numeric" })}
              </Typography>
            </GridItem>
          </GridItem>
        </GridItem>
        <GridItem sx={styles.outlineBox}>
          <GridItem sx={styles.box}>
            <GridItem sx={styles.heading}>
              <Typography variant="body2" sx={styles.headingTypography}>
                Updated At
              </Typography>
            </GridItem>
            <Divider sx={{ mt: "-5px" }} />
            <GridItem sx={styles.content}>
              <Typography variant="body2" sx={styles.valueTypography}>
                {new Date(apiKey?.updatedAt as string).toLocaleDateString("de-CH", { day: "2-digit", month: "2-digit", year: "numeric" })}
              </Typography>
            </GridItem>
          </GridItem>
        </GridItem>
      </GridContainer>
    </GridItem>
  )
}
export default ApiKeyPage
