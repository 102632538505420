import React, { useRef, useMemo, useCallback } from "react"
import { AgGridReact } from "ag-grid-react"
import { GetContextMenuItemsParams, LicenseManager } from "ag-grid-enterprise"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import StatusRenderer from "../../table/StatusRenderer"
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined"
import "../../../customCss/table-styles.css"
import CenterHeader from "../../basic/grid/CenterHeader"
import { Subdomain } from "../../../repository/DomainsRepository"
import { useNavigate, useParams } from "react-router-dom"

LicenseManager.setLicenseKey(
  "CompanyName=Appmodule AG,LicensedGroup=aliunid grid / power,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-036901,SupportServicesEnd=31_January_2024_[v2]_MTcwNjY1OTIwMDAwMA==4c9d5d4b04b142bc6b50ed895e5b5ef3"
)

interface Props {
  setOpenCreateSubdomainDialog: React.Dispatch<React.SetStateAction<boolean>>
  setSubdomainInfoData: React.Dispatch<React.SetStateAction<Subdomain | undefined>>
  setOpenDeleteSubdomainDialog: React.Dispatch<React.SetStateAction<boolean>>
  rowData: Subdomain[]
}

const SubdomainGrid = ({ setOpenCreateSubdomainDialog, setSubdomainInfoData, setOpenDeleteSubdomainDialog, rowData }: Props) => {
  const navigate = useNavigate()
  const gridRef = useRef<AgGridReact>(null)
  const settings = JSON.parse(localStorage.getItem("settings") || "")

  const { domainName } = useParams()

  const columnDefs = [
    {
      field: "name",
      headerName: "Subdomains",
      autoHeight: true,
      filter: true,
      cellStyle: function () {
        return { display: "flex", alignItems: "center", fontWeight: "bold" }
      },
      minWidth: 150,
    },
    {
      field: "things",
      headerName: "Things",
      headerClass: "ag-right-aligned-header",
      cellStyle: function () {
        return { fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "end" }
      },
      minWidth: 100,
    },
    {
      field: "status",
      headerName: "Status",
      headerComponent: CenterHeader,
      cellRenderer: (row: any) => <StatusRenderer value={"AVAILABLE"} />,
      cellStyle: function () {
        return { display: "flex", alignItems: "center", justifyContent: "center" }
      },
      minWidth: 100,
    },
    {
      field: "apikeys",
      headerName: "API Keys",
      filter: true,
      headerComponent: CenterHeader,
      cellStyle: function () {
        return { fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "center" }
      },
      cellRenderer: (row: any) => row.data.apiKeys?.length,
      minWidth: 170,
    },
    {
      field: "actions",
      headerName: ". . .",
      cellRenderer: (row: any) => <MenuOutlinedIcon sx={{ color: "#666666" }} />,
      cellStyle: function () {
        return { display: "flex", alignItems: "center", cursor: "pointer" }
      },
      maxWidth: 70,
      minWidth: 70,
      onCellClicked: function (params: any) {
        if (params.node.data.name !== "") {
          params.api.contextMenuFactory.showMenu(params.node, params.column, params.value, params.event)
        }
      },
    },
  ]

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      wrapText: false,
      suppressMovable: true,
      flex: 1,
      suppressMenu: true,
    }),
    []
  )

  const userIcon = '<img border="0" width="15" height="15" src="/images/user-circle.svg"/>'
  const deleteIcon = '<img border="0" width="15" height="15" src="/images/delete_icon.svg"/>'
  const searchIcon = '<img border="0" width="15" height="15" src="/images/icon.svg"/>'

  const getContextMenuItems = useCallback((params: GetContextMenuItemsParams) => {
    const result = []
    if (params.node) {
      // result.push(
      //   {
      //     name: "Edit",
      //     icon: editIcon,
      //     action: function () {
      //       setSubdomainInfoData(params.node?.data)
      //       setOpenCreateSubdomainDialog(true)
      //     },
      //   },
      //   "separator"
      // )
      result.push({
        name: "Delete",
        icon: deleteIcon,
        action: function () {
          setSubdomainInfoData(params.node?.data)
          setOpenDeleteSubdomainDialog(true)
        },
      },
      "separator"
      )
      result.push({
        name: "Api Keys details",
        icon: searchIcon,
        action: function () {
          if (typeof params.node?.data !== "undefined" && params.node.data.name !== "") {
            navigate(`${params.node.data.name}`)
          } else {
            //setMpData(params.node?.data)
          }
        },
      },"separator"
      )
      result.push(
        {
          name: "Open SIOT Generic-Client",
          icon: userIcon,
          action: function () {
            if (typeof params.node?.data !== "undefined" && params.node.data.name !== "") {
              window.open(`${settings.SIOT_CLIENT_URL}/${settings.SIOT_CLIENT_SERVER_URL}/${domainName}/${params.node.data?.name}`, '_blank')
            }
          },
        },
        "separator"
      )
      result.push(
        {
          name: "<span style='color: #c4c2c2;'>Things details</span>",
          icon: searchIcon,
          // action: function () {
          //   if (typeof params.node?.data !== "undefined" && params.node.data.name !== "") {
              
          //   }
          // },
        },
        "separator"
      )
    }
    return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cellClicked = useCallback((row: any) => {
    if (typeof row.data !== "undefined" && row.column.colId !== "actions") {
      navigate(`${row.data.name}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRowStyle = (params: any) => {
    return { background: "white", cursor: "pointer" }
  }

  return (
    <div>
      <div className="ag-theme-alpine domainsGrid" style={{ width: "auto", height: "calc(100vh - 350px)" }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          getRowStyle={getRowStyle}
          columnDefs={columnDefs}
          animateRows={true}
          rowSelection="multiple"
          groupDisplayType="groupRows"
          groupDefaultExpanded={1}
          defaultColDef={defaultColDef}
          suppressContextMenu={true}
          getContextMenuItems={getContextMenuItems}
          pagination={true}
          paginationAutoPageSize={true}
          onCellClicked={cellClicked}
        />
      </div>
    </div>
  )
}

export default SubdomainGrid
