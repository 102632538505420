import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import DependencyContainer from "../../DependencyContainer"
import { Subdomain } from "../../repository/DomainsRepository"
import GridItem from "../../components/basic/grid/GridItem"
import { Divider, Typography } from "@mui/material"
import SubdomainsGrid from "../../components/advanced/grids/SubdomainsGrid"
//import { useLanguage } from "../../languageContext"
import Search from "../../components/basic/search/Search"
import ContainedButton from "../../components/basic/buttons/ContainedButton"
import SendIcon from "@mui/icons-material/Send"
import { AppTheme } from "../../AppTheme"
import Breadcrumb from "../../components/advanced/breadcrumb/Breadcrumb"
import CreateSubdomainModal from "../../components/advanced/modals/CreateSubdomainModal"
import DeleteModal from "../../components/advanced/modals/DeleteModal"

const styles = {
  box: {
    width: "100%",
    height: "150px",
    background: "white",
    borderLeft: "1px solid #babfc7",
    borderTop: "1px solid #babfc7",
    borderRight: "1px solid #babfc7",
  },
  dashboard: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "white",
    my: "auto",
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    p: "15px 20px",
    background: AppTheme.palette.primary.dark,
  },
  flex1: {
    display: "flex",
    alignItems: "center",
    height: "80px",
  },
  iconSettings: {
    cursor: "pointer",
  },
  apikeys: {
    fontSize: "20px",
    fontWeight: "bold",
    ml: "20px",
  },
  shared: {
    color: "green",
    fontSize: "20px",
    fontWeight: "bold",
    ml: "10px",
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "100px",
    background: "green",
    mt: "4px",
    mr: "10px",
  },
  domainButton: {
    ml: "auto",
    mr: "20px",
  },
  domainButton1: {
    textTransform: "none",
    fontWeight: "bold",
  },
  iconSend: {
    transform: "rotate(-40deg)",
    mb: "6px",
  },
}

const SubdomainsPage = () => {
  const [subdomains, setSubdomains] = useState<Subdomain[]>([])
  const [searchWord, setSearchWord] = useState("")
  const [openCreateSubdomainDialog, setOpenCreateSubdomainDialog] = useState(false)
  const [openDeleteSubdomainDialog, setOpenDeleteSubdomainDialog] = useState(false)
  const [filteredData, setFilteredData] = useState<Subdomain[]>([])
  const [subdomainInfoData, setSubdomainInfoData] = useState<Subdomain>()

  //const { t } = useLanguage()
  const { domainName } = useParams()

  const dependencyContainer = useMemo(() => new DependencyContainer(), [])
  dependencyContainer.createDependency()

  useQuery({
    queryKey: ["subdomainsData"],
    queryFn: () => dependencyContainer.dependency.subdomainsRepositoryApi.getSubdomains(domainName as string),
    onSuccess: (data) => {
      setSubdomains(data)
    },
    onError: (error: any) => {
      console.log(error)
    },
  })

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchWord = event.target.value
    setSearchWord(searchWord)
    const filteredList = subdomains.filter((subdomain: Subdomain) => {
      return subdomain.name.toLowerCase().includes(searchWord.toLowerCase())
    })
    setFilteredData(filteredList)
  }

  const resetSearchWord = () => {
    setSearchWord("")
    setFilteredData(subdomains)
  }

  useEffect(() => {
    setFilteredData(subdomains)
  }, [subdomains])

  const handleClickOpenCreateDomainDialog = () => {
    setOpenCreateSubdomainDialog(true)
  }

  return (
    <GridItem sx={{ p: 3 }}>
      <GridItem sx={styles.box}>
        <GridItem sx={styles.flex}>
          <GridItem sx={styles.dashboard}>
            <Breadcrumb domain={domainName} />
          </GridItem>
          <Search handleSearch={handleSearch} searchWord={searchWord} resetSearchWord={resetSearchWord} />
        </GridItem>
        <Divider />
        <GridItem sx={styles.flex1}>
        <Typography sx={styles.apikeys}>Subdomains</Typography>
          <GridItem sx={styles.domainButton}>
            <ContainedButton sx={styles.domainButton1} endIcon={<SendIcon sx={styles.iconSend} />} onClick={handleClickOpenCreateDomainDialog}>
              Create Subdomain
            </ContainedButton>
          </GridItem>
        </GridItem>
      </GridItem>
      <SubdomainsGrid
        setOpenCreateSubdomainDialog={setOpenCreateSubdomainDialog}
        setSubdomainInfoData={setSubdomainInfoData}
        setOpenDeleteSubdomainDialog={setOpenDeleteSubdomainDialog}
        rowData={filteredData}
      />
      <CreateSubdomainModal
        openCreateSubdomainDialog={openCreateSubdomainDialog}
        setOpenCreateSubdomainDialog={setOpenCreateSubdomainDialog}
        setSubdomainInfoData={setSubdomainInfoData}
        subdomainInfoData={subdomainInfoData}
      />
      <DeleteModal
        openDeleteDialog={openDeleteSubdomainDialog}
        setOpenDeleteDialog={setOpenDeleteSubdomainDialog}
        infoData={subdomainInfoData}
        setInfoData={setSubdomainInfoData}
        flag={"subdomains"}
      />
    </GridItem>
  )
}
export default SubdomainsPage
