import React from "react"
import GridContainer from "../../components/basic/grid/GridContainer"
import { Divider, Link, Typography } from "@mui/material"
import ContainedButton from "../../components/basic/buttons/ContainedButton"
import { AppTheme } from "../../AppTheme"

const styles = {
  button: {
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "15px",
    borderRadius: "20px",
    color: "white",
    mt: "-5px",
    backgroundColor: AppTheme.palette.primary.main,
    "&:hover": {
      color: "#f2f3f5",
      transition: ".4s ease",
      backgroundColor: AppTheme.palette.primary.main,
    },
  },
  grid: {
    display: "flex",
  },
}

const BusinessPortalPage = () => {
  const token = localStorage.getItem("token")
  const settings: any = JSON.parse(localStorage.getItem("settings") || "")

  return (
    <GridContainer sx={styles.grid}>
      <GridContainer>
        <Typography variant="body2" sx={{ fontSize: "26px", fontWeight: "bold", color: "gray" }}>
          Management
        </Typography>
        <Divider sx={{ width: "100%", mt: "10px", mb: "30px" }} />
      </GridContainer>
      <GridContainer sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body2" sx={{ fontSize: "18px" }}>
        Manage your SIOT account and subscription on the SIOT business platform
        </Typography>
        <ContainedButton
          sx={styles.button}
          component={Link}
          href={`${settings.BUSINESS_PORTAL_URL}/?token=${token}`}
          underline="none"
        >
          SIOT Business Portal
        </ContainedButton>
      </GridContainer>
    </GridContainer>
  )
}

export default BusinessPortalPage
