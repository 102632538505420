import axios from "axios"
import ApiKeysRepository, { APIKey } from "./ApiKeysRepository"

export default class APIApiKeysRepository implements ApiKeysRepository {
  async apiKeys(domain: string, subdomain: string): Promise<APIKey[]> {
    const settings: any = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")

    const data = await axios.get(`${settings.SSP_BACKEND_URL}/v1/siot/${domain}/${subdomain}/api-keys`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.data?.data
  }

  async createApiKey(domain: string, subdomain: string, description: string): Promise<APIKey> {
    const settings: any = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")

    const body = {
      description: description,
    }

    const data = await axios.post(`${settings.SSP_BACKEND_URL}/v1/siot/${domain}/${subdomain}/api-keys`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.data?.data
  }

  async getApiKey(domain: string, subdomain: string, apiShortToken: string): Promise<APIKey> {
    const settings: any = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")

    const data = await axios.get(`${settings.SSP_BACKEND_URL}/v1/siot/${domain}/${subdomain}/api-keys/${apiShortToken}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.data?.data
  }

  async updateApiKey(domain: string, subdomain: string, description: string, apiShortToken: string): Promise<APIKey> {
    const settings: any = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")

    const body = {
      description: description,
    }

    const data = await axios.patch(`${settings.SSP_BACKEND_URL}/v1/siot/${domain}/${subdomain}/api-keys/${apiShortToken}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.data?.data
  }

  async deleteApiKey(domain: string, subdomain: string, apiShortToken: string): Promise<void> {
    const settings: any = JSON.parse(localStorage.getItem("settings") || "")
    const token = localStorage.getItem("token")

    const data = await axios.delete(`${settings.SSP_BACKEND_URL}/v1/siot/${domain}/${subdomain}/api-keys/${apiShortToken}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data.data?.data
  }
}
