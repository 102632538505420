import React, { useEffect, useState } from "react"
import GridItem from "../../components/basic/grid/GridItem"
import { Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material"
import DashboardMap from "../../components/advanced/maps/DashboardMap"
import DomainsDonut from "../../components/advanced/charts/DomainsDonut"
import DomainsGridSmall from "../../components/advanced/grids/DomainsGridSmall"
import GridContainer from "../../components/basic/grid/GridContainer"
import { DemoData, Domain, SubdomainFromMembership } from "../../repository/DomainsRepository"
import DependencyContainer from "../../DependencyContainer"
import { useQuery } from "@tanstack/react-query"
import UsageGraph from "../../components/advanced/charts/UsageGraph"

const styles = {
  outlineBox: {
    flex: 1,
    flexWrap: "wrap",
    minWidth: { xs: "100%", sm: "250px", md: "250px", lg: "250px", xl: "250px" },
    width: { xs: "100%" },
  },
  outlineBoxWider: {
    flex: 2,
    flexWrap: "wrap",
    minWidth: { xs: "100%", sm: "500px", md: "500px", lg: "500px", xl: "500px" },
    width: { xs: "100%" },
    border: "1px solid #babfc7",
    p: "15px",
  },
  box: {
    background: "white",
    border: "1px solid #babfc7",
  },
  typography: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  heading: {
    p: "15px 20px",
  },
  content: {
    mt: "30px",
    alignItems: "center",
    height: "450px",
  },
  content1: {
    mt: "10px",
    alignItems: "center",
    height: "400px",
  },
}

const DashboardPage = () => {
  const [domains, setDomains] = useState<Domain[]>([])
  const [resolution, setResolution] = useState("year")
  const [graphDataFromDomains, setGraphDataFromDomains] = useState<DemoData[]>([])
  const [selectedSubdomain, setSelectedSubdomain] = useState("")

  useEffect(() => {
    if (graphDataFromDomains?.length > 0) setSelectedSubdomain("SUM")
  }, [graphDataFromDomains])

  const handleChangeResolution = (event: SelectChangeEvent) => {
    setResolution(event.target.value)
  }

  const handleChangeSelectedSubdomain = (event: SelectChangeEvent) => {
    setSelectedSubdomain(event.target.value)
  }

  const dependencyContainer = new DependencyContainer()
  dependencyContainer.createDependency()

  useQuery({
    queryKey: ["data"],
    queryFn: () => dependencyContainer.dependency.domainsRepositoryApi.memberships(),
    onSuccess: (data) => {
      setDomains(
        data.domainMemberships.map((domain: Domain) => ({
          ...domain,
          subdomains: data.subdomainMemberships.filter((subdomain: SubdomainFromMembership) => subdomain.domainName === domain.domainName),
        }))
      )
      if(data.demoData !== undefined){
        setGraphDataFromDomains(data.demoData)
      } else {
        setGraphDataFromDomains([])
      }
    },
    onError: (error: any) => {
      console.log(error)
    },
  })

  return (
    <GridItem sx={{ p: 3 }}>
      <GridContainer sx={{ gap: "15px", mb: "15px" }}>
        <GridItem sx={styles.outlineBoxWider}>
          <GridItem sx={{ height: "450px", width: "100%" }}>
            <GridItem sx={{ display: "flex", mt: "10px" }}>
              <GridContainer sx={{ mb: "15px" }}>
                <Typography variant="body2" sx={{ fontSize: "16px", textTransform: "uppercase", fontWeight: "bold" }}>
                  Usage history
                </Typography>
              </GridContainer>
              <GridContainer sx={{ mb: "15px" }}>
                <FormControl sx={{ ml: "auto", minWidth: 140 }} size="small">
                  <InputLabel sx={{ fontSize: "14px", fontWeight: "bold" }}>Select Subdomain</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedSubdomain}
                    label="Select Subdomain"
                    onChange={handleChangeSelectedSubdomain}
                  >
                    {graphDataFromDomains?.map((subdomain: DemoData) => (
                      <MenuItem key={subdomain?.subdomain} value={subdomain?.subdomain}>
                        {subdomain?.subdomain === "SUM" ? "All subdomains" : subdomain?.subdomain}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ ml: "auto", minWidth: 140 }} size="small">
                  <InputLabel sx={{ fontSize: "14px", fontWeight: "bold" }}>Resolution</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={resolution}
                    label="Resolution"
                    onChange={handleChangeResolution}
                  >
                    <MenuItem value={"year"}>Year</MenuItem>
                    <MenuItem value={"month"}>Month</MenuItem>
                    <MenuItem value={"week"}>Week</MenuItem>
                    <MenuItem value={"day"}>Day</MenuItem>
                  </Select>
                </FormControl>
              </GridContainer>
            </GridItem>
            <UsageGraph rowData={graphDataFromDomains} resolution={resolution} selectedSubdomain={selectedSubdomain} />
          </GridItem>
        </GridItem>
        <GridItem sx={styles.outlineBox}>
          <GridItem sx={styles.box}>
            <GridItem sx={styles.heading}>
              <Typography variant="body2" sx={styles.typography}>
                Things by Location
              </Typography>
            </GridItem>
            <Divider sx={{ mt: "-5px" }} />
            <GridItem sx={styles.content} style={{padding: "15px"}}>
              <DashboardMap />
            </GridItem>
          </GridItem>
        </GridItem>
      </GridContainer>
      <GridContainer sx={{ gap: "15px" }}>
        <GridItem sx={styles.outlineBox}>
          <GridItem sx={styles.box}>
            <GridItem sx={styles.heading}>
              <Typography variant="body2" sx={styles.typography}>
                Domains by Status
              </Typography>
            </GridItem>
            <Divider sx={{ mt: "-5px" }} />
            <GridItem sx={styles.content}>
              <DomainsDonut data={domains} />
            </GridItem>
          </GridItem>
        </GridItem>
        <GridItem sx={styles.outlineBoxWider}>
          <GridItem sx={styles.box}>
            <GridItem sx={styles.heading}>
              <Typography variant="body2" sx={styles.typography}>
                Domains
              </Typography>
            </GridItem>
            <Divider sx={{ mt: "-5px" }} />
            <GridItem sx={styles.content1}>
              <DomainsGridSmall rowData={domains} />
            </GridItem>
          </GridItem>
        </GridItem>
      </GridContainer>
    </GridItem>
  )
}
export default DashboardPage
