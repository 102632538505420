import React from "react"
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from "react-router-dom"
import DashboardPage from "../pages/mainPages/DashboardPage"
import Navigation from "../components/advanced/sideBar/SideBar"
import SubdomainsPage from "../pages/mainPages/SubdomainsPage"
import DomainsPage from "../pages/mainPages/DomainsPage"
import ApiKeysPage from "../pages/mainPages/ApiKeysPage"
import AppLoader from "../appLoader/AppLoader"
import AppKeycloak from "../Keycloak"
import ScrollToTop from "../ScrollToTop"
import ImpressumPage from "../pages/otherPages/ImpressumPage"
import PrivacyPolicyPage from "../pages/otherPages/PrivacyPolicyPage"
import ApiKeyPage from "../pages/mainPages/ApiKeyPage"
import BusinessPortalPage from "../pages/mainPages/BusinessPortalPage"

const token = localStorage.getItem("token")

const protectedRoutesSiot = [
  {
    index: true,
    path: "dashboard",
    element: <DashboardPage />,
  },
  {
    path: "domains",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <DomainsPage />,
      },
      {
        path: ":domainName",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <SubdomainsPage />,
          },
          {
            path: ":subdomainName",
            element: <Outlet />,
            children: [
              {
                index: true,
                element: <ApiKeysPage />,
              },
              {
                path: "apiKey/:apiKeyId",
                element: <ApiKeyPage />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "business-portal",
    element: <BusinessPortalPage />,
  },
  {
    path: "impressum",
    element: <ImpressumPage />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "*",
    element: <Navigate replace to={"/dashboard"} />,
  },
  {
    path: "/",
    element: <Navigate to="/dashboard" replace={true} />,
  },
]

// const protectedRoutesIotools = [
//   {
//     index: true,
//     path: "dashboard",
//     element: <DashboardIotoolsPage />,
//   },
//   {
//     path: "api-keys",
//     element: <ApiKeysPage />,
//   },
//   {
//     path: "API-documentation",
//     element: <ApiDocsPage />,
//   },
//   {
//     path: "ticketing",
//     element: <TicketingPage />,
//   },
//   {
//     path: "contact",
//     element: <ContactPage />,
//   },
//   {
//     path: "/impressum",
//     element: <ImpressumPage />,
//   },
//   {
//     path: "/privacy-policy",
//     element: <PrivacyPolicyPage />,
//   },
//   {
//     path: "*",
//     element: <Navigate replace to={"/dashboard"} />,
//   },
//   {
//     path: "/",
//     element: <Navigate to="/dashboard" replace={true} />,
//   },
// ]

export default function AppRouter() {
  const router = React.useMemo(() => {
    const adminApp = {
      element: <Navigation />,
      children: [...protectedRoutesSiot],
    }

    return createBrowserRouter([
      {
        path: "/",
        element: !token ? <AppLoader keycloakStore={AppKeycloak.getAppKeycloak().store()} /> : <Navigate to={"/dashboard"} replace={true} />,
      },
      {
        path: "/",
        element: (
          <ScrollToTop>
            <Outlet />
          </ScrollToTop>
        ),
        children: [adminApp],
      },
    ])
  }, [])

  return <RouterProvider router={router} />
}
