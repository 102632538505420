import React, { useRef, useMemo, useCallback } from "react"
import { AgGridReact } from "ag-grid-react"
import { useNavigate } from "react-router-dom"
import { LicenseManager } from "ag-grid-enterprise"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import StatusRenderer from "../../table/StatusRenderer"
import NumberRenderer from "../../table/NumberRenderer"
import "../../../customCss/table-styles.css"
import CenterHeader from "../../basic/grid/CenterHeader"
import { Domain } from "../../../repository/DomainsRepository"

LicenseManager.setLicenseKey(
  "CompanyName=Appmodule AG,LicensedGroup=aliunid grid / power,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-036901,SupportServicesEnd=31_January_2024_[v2]_MTcwNjY1OTIwMDAwMA==4c9d5d4b04b142bc6b50ed895e5b5ef3"
)

interface Props {
  rowData: Domain[]
}

const DomainsGridSmall = ({ rowData }: Props) => {
  const gridRef = useRef<AgGridReact>(null)
  const navigate = useNavigate()

  const columnDefs = [
    {
      field: "domainName",
      headerName: "Domains",
      filter: true,
      cellStyle: function () {
        return { display: "flex", alignItems: "center" }
      },
      minWidth: 170,
    },
    {
      field: "subdomain",
      headerName: "Subdomains",
      headerClass: "ag-right-aligned-header",
      cellStyle: function () {
        return { fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "end" }
      },
      cellRenderer: (row: any) => row.data.subdomains.length,
      minWidth: 170,
    },
    {
      field: "things",
      headerName: "Things",
      headerClass: "ag-right-aligned-header",
      cellStyle: function () {
        return { fontWeight: "bold", display: "flex", alignItems: "center", justifyContent: "end" }
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerComponent: CenterHeader,
      cellRenderer: (row: any) => <StatusRenderer value={"AVAILABLE"} />,
      cellStyle: function () {
        return { display: "flex", alignItems: "center", justifyContent: "center" }
      },
    },
  ]

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      wrapText: false,
      suppressMovable: true,
      flex: 1,
    }),
    []
  )

  const cellClicked = useCallback((row: any) => {
    if (typeof row.data !== "undefined") {
      navigate(`/domains/${row.data.domainName}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRowStyle = (params: any) => {
    return { background: "white", cursor: "pointer" }
  }

  return (
    <div className="ag-theme-alpine domainsGridSmall" style={{ width: "auto", height: "400px" }}>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        animateRows={true}
        rowSelection="multiple"
        groupDisplayType="groupRows"
        groupDefaultExpanded={1}
        defaultColDef={defaultColDef}
        getRowStyle={getRowStyle}
        onCellClicked={cellClicked}
      />
    </div>
  )
}

export default DomainsGridSmall
