import * as React from "react"
import Typography from "@mui/material/Typography"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import { Link } from "react-router-dom"
import "./breadcrumb-style.css"

interface BreadcrumbProps {
  domain: string | undefined
  subdomain?: string | undefined
  apiKeyId?: string | undefined
}

const styles = {
  dashboard: {
    fontSize: "19px",
    fontWeight: "bold",
    color: "white",
    my: "auto",
    textDecoration: "none",
  },
}

const Breadcrumb = ({ domain, subdomain, apiKeyId }: BreadcrumbProps) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={styles.dashboard}>
      <Link className='breadcrumb-link' to="/domains">
        Domains
      </Link>
      {subdomain && !apiKeyId &&
        <Link className='breadcrumb-link' to={`/domains/${domain}`}>
          {domain}
        </Link>
      }

      {apiKeyId &&
        <Link className='breadcrumb-link' to={`/domains/${domain}`}>
          {domain}
        </Link>
      }

      {apiKeyId &&
        <Link className='breadcrumb-link' to={`/domains/${domain}/${subdomain}`}>
          {subdomain}
        </Link>
      }

      {!subdomain && !apiKeyId && <Typography sx={styles.dashboard}>{domain}</Typography>}
      {subdomain && !apiKeyId && <Typography sx={styles.dashboard}>{subdomain}</Typography>}
      {apiKeyId && <Typography sx={styles.dashboard}>{apiKeyId}</Typography>}
    </Breadcrumbs>
  )
}
export default Breadcrumb
